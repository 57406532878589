import React, { useState } from 'react'
import Header from '../component/SalesInfo/Header'
import YearlySalesTable from '../component/CategoryWiseSales/YearlySalesTable'
import FinancialYearPicker from '../component/FinancialYearPicker/FinancialYearPicker'
import SalesRewardsChart from '../component/SalesRewardsChart/SalesRewardsChart'
import PaymentBtn from '../component/PaymentInfo/PaymentBtn'

function StarRewards() {
  let d = new Date()
  let endYear = d.getFullYear()
  let currentMonth = d.getMonth()

  // in case of financial - if currentMonth is after march - endYear will be endYear+1

  if(currentMonth > 2) {
    endYear = endYear + 1
  }

  const [selectedYear, setSelectedYear] = useState(`${endYear-1}-${endYear}`)
  const [startYear, setStartYear] = useState(2018)
  const [chartData, setChartData] = useState({
          
    series: [
    {
      name: 'CYS',
      data: [69, 35]
    }, 
    {
      name: 'PYS',
      data: [56, 15]
    }
  ],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Wiring Devices', 'Wires, Light & Pipes'],
      },
      yaxis: {
        title: {
          text: '* values are in Lacs'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹ " + val + " Lacs"
          }
        }
      }
    },
  })

  const previousYearArr = selectedYear?.split("-")
  const salesSummaryHeader = {
    name1: "Division",
    name2: `PYS (${previousYearArr[0] - 1}-${previousYearArr[1] - 1})`,
    name3: "Target to Reach",
    name4: `CYS (${selectedYear})`,
    name5: "Short Fall/Excess",
  }

  const salesSummaryData = [
    {
      division: "Wiring Devices",
      ysa: "₹ 12,34,567",
      overallChg: "₹ 12,34,567",
      sales: "₹ 12,34,567",
      overallChgPercent: "₹ 12,34,567",
    },
    {
      division: "Wires, Lights & Pipes",
      ysa: "₹ 12,34,567",
      overallChg: "₹ 12,34,567",
      sales: "₹ 12,34,567",
      overallChgPercent: "₹ 12,34,567",
    }
  ]

  const rewardHeader = {
    name1: "Division",
    name2: "Percentage",
    name3: "Amount",
    }

  const rewardData = [
    {
      division: "Wiring Devices",
      ysa: "Bonus 1%",
      overallChg: "-",
      sales: "Additional Bonus 2%",
      overallChgPercent: "-",
    },
    {
      division: "Wiring Devices",
      ysa: "Bonus 1%",
      overallChg: "-",
      sales: "Additional Bonus 2%",
      overallChgPercent: "-",
    },
    {
      division: "Total",
      ysa: "-",
      overallChg: "-",
      sales: "-",
      overallChgPercent: "-",
    },
  ]

  return (
    <div>
      <img style={{width: "60%"}} src="/assets/images/starReward.jpg" alt="reward img" />
      <FinancialYearPicker 
      selectedYear={selectedYear} 
      setSelectedYear={setSelectedYear}
      startYear={startYear}
      endYear={endYear}
      isStarReward={true}
      />
      <Header text="Sales Summary" />
      <div className='my-2'>
        <YearlySalesTable column={salesSummaryHeader} data={salesSummaryData} />
      </div>
      <Header text="Rewards" />
      <div className='my-2'>
        <YearlySalesTable column={rewardHeader} data={rewardData} />
      </div>
      <Header text="Division Wise Performance" />
      <SalesRewardsChart data={chartData} />
      <PaymentBtn text="View Info" style={{ border: 0 , borderRadius: 0}} />
    </div>
  )
}

export default StarRewards