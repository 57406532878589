import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalItem from './ModalItem';
import moment from 'moment';


function SchemeDetailModal({show, onHide , clickedScheme, schemeDetail}) {

  return (
    <div className='mx-auto'>
    <Modal isOpen={show} toggle={onHide} className="mx-5ß d-flex" style={{ marginTop: "10vh"}} >
      <ModalHeader className='text-center' toggle={onHide}>Scheme Detail</ModalHeader>
      <ModalBody style={{ overflowY: "auto", maxHeight: "70vh"}}>

        {/* common in all - invoice scheme, order scheme, growth scheme */}
        <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Name"} text={clickedScheme?.SchemeName} />
            </div>
            <div style={{width: "50%"}}>
            <ModalItem heading={"Scheme Type"} text={clickedScheme?.SchemeTypeName} />
            </div>
        </div>
        <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Start Date"} text={moment(clickedScheme?.StartDate, "DD/MM/YYYY").format("DD-MM-YYYY")} />
            </div>
            <div style={{width: "50%"}}>
            <ModalItem heading={"End Date"} text={moment(clickedScheme?.EndDate, "DD/MM/YYYY").format("DD-MM-YYYY")} />
            </div>
        </div>

        {clickedScheme && clickedScheme?.TotalValues && <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Total Values"} text={clickedScheme?.TotalValues} />
            </div>
            <div style={{width: "50%"}}>
            <ModalItem heading={"Points"} text={clickedScheme?.Points} />
            </div>
        </div>}
        {/* common item end */}

        {/* invoice scheme */}
        {!clickedScheme?.TotalValues && clickedScheme?.InvoiceSchemeID && schemeDetail?.length > 0 && schemeDetail[0].SlabJson &&  schemeDetail.map((item, i) => (
            <div key={i}>
            <div className='d-flex gap-1 mx-3'>
            <p style={{fontWeight: 600}}>Sku Unique Id : </p>
            <p>{item?.SkuUniqueId}</p>
            </div>
            <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Sku Id"} text={JSON.parse(item?.SlabJson)[i]?.SkuID} />
            </div>
            <div style={{width: "50%"}}>
            <ModalItem heading={"Point"} text={JSON.parse(item?.SlabJson)[i]?.Point} />
            </div>
            </div>
            <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Quantity"} text={JSON.parse(item?.SlabJson)[i]?.Quantity} />
            </div>
            <div style={{width: "50%"}}>
            <ModalItem heading={"TotalValues"} text={JSON.parse(item?.SlabJson)[i]?.TotalValues} />
            </div>
            </div>
            </div>
        ))}
        {/* invoice scheme end */}

        {/* order scheme start */}
        {!clickedScheme?.TotalValues && clickedScheme?.OrderSchemeID || clickedScheme?.GrowthSchemeID && schemeDetail?.length > 0 && schemeDetail.map((item, i) => (
            <div key={i}>
            <div className='d-flex gap-1 mx-3'>
            <p style={{fontWeight: 600}}>Sku Unique Id : </p>
            <p>{item?.SkuUniqueId}</p>
            </div>
            {JSON.parse(item.SlabJson).map((el, j) =>  (
            <div key={j}>
            <div className='m-3 d-flex justify-content-between'>
            <div>
              <ModalItem heading={"Slab No"} text={el?.SlabNo} />
            </div>
            <div style={{width: "50%"}}>
              <ModalItem heading={"From"} text={el?.From} />
            </div>
            </div>
            <div className='m-3 d-flex justify-content-between'>
            <div>
              <ModalItem heading={"To"} text={el?.To} />
            </div>
            <div style={{width: "50%"}}>
              <ModalItem heading={"Points"} text={el?.Point} />
            </div>
            </div>
            </div>
            ))}
            </div>
            
        ))}
        {/* order scheme end */}
        
      </ModalBody>
    </Modal>
    </div>
  )
}

export default SchemeDetailModal