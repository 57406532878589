import React, { useEffect, useState } from 'react'
import SalesInfoHeader from '../component/SalesInfoHeader/SalesInfoHeader'
import SalesInfo from '../component/SalesInfo/SalesInfo'
import OrdersInfo from "../component/OrdersInfo/OrdersInfo"
import PaymentInfo from "../component/PaymentInfo/PaymentInfo"
import SchemeInfo from '../component/SchemeInfo/SchemeInfo'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function SalesInfoPage() {
    const [activeHeaderTab, setActiveHeaderTab] = useState(1)
    const history = useHistory()

    const headerData = [
        {
            id: 1,
            title: "Sales",
            icon: "/assets/images/salesInfo/sales.svg",
            width: "20px"
        },
        {
            id: 2,
            title: "orders",
            icon: "/assets/images/salesInfo/orders.svg",
            width: "20px"
        },
        {
            id: 3,
            title: "payment",
            icon: "/assets/images/salesInfo/payment.svg",
            width: "20px"
        },
        {
            id: 4,
            title: "scheme",
            icon: "/assets/images/salesInfo/sales.svg",
            width: "20px"
        }
    ]

    // redirecting to order's page

    useEffect(() => {
        if(activeHeaderTab === 2) {
            history.push("/order")
        }
    }, [activeHeaderTab])
   

  return (
    <div>
        <SalesInfoHeader 
        data={headerData}
        active={activeHeaderTab}
        setActive={setActiveHeaderTab}
        />

        {activeHeaderTab === 1 && <SalesInfo />}
        {activeHeaderTab === 3 && <PaymentInfo />}
        {activeHeaderTab === 4 && <SchemeInfo />}

    </div>
  )
}

export default SalesInfoPage