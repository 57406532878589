import React, { useEffect, useState } from "react";
import SalesTable from "./SalesTable";
import SalesChart from "./SalesChart";
import Header from "../SalesInfo/Header";
import CategoryBtn from "./CategoryBtn";
import { asyncWrap } from "../../utils/utils";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import YearlySalesTable from "./YearlySalesTable";
import axios from "axios";
import { Select } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function CategoryWiseSales() {
  const [homePageBanner, setHomePageBanner] = useState();
  const [fySkuWiseData, setFySkuWiseData] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [currentFyType, setCurrentFyType] = useState("current")
  const [chartData, setChartData] = useState({
    series: [],
    // series: [55, 24, 10, 6, 3, 0.3, 0.16],
    options: {
      labels: [],
      // labels: ["Wiring Devices", "Air", "Urea", "Curve Plus", "C9 Modular", "Gazelle Wood"],
      colors: ["#FF3031", "#3498DB", "#129842", "#EA7773", "#BB2CD9", "#758AA2", "#FAC42F", "#192A56", "#392e4a", "#e6ff6e"],
      chart: {
        type: "donut",
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 310,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });
  
  const column = [
    {
      id: 1,
      name: "Category",
    },
    {
      id: 2,
      name: "Amount",
    },
    {
      id: 3,
      name: "Quantity",
    },
    {
      id: 4,
      name: "Percentage",
    },
  ];

//   the data from the backend should be in descending order

  const data = fySkuWiseData.map(item => ({
    category: item.SkuTypeName,
    amount: `₹ ${item.TotalValue.toLocaleString()}`, // Assuming you want the amount formatted as currency
    quantity: item.TotalQty.toString(),
    percentage: `${((item?.TotalValue / totalAmount) * 100).toFixed(1)}%`
  }))

  // adding the total field if their is some data
  if(data.length > 0) {
    data.push({
      category: "Total",
      amount: `₹ ${totalAmount.toLocaleString()}`,
      quantity: "",
      percentage: "100%"
    })
  }

  // const yearlySalesHeader = {
  //     id: 1,
  //     name1: "Division",
  //     name2: "YSA",
  //     name3: "Overall Chg",
  //     name4: "Sales",
  //     name5: "Overall Chg %",
  //   }

  // const yearlySalesData = [
  //   {
  //     division: "₹ 12,34,567",
  //     ysa: "₹ 12,34,567",
  //     overallChg: "₹ 12,34,567",
  //     sales: "₹ 12,34,567",
  //     overallChgPercent: "₹ 12,34,567",
  //   },
  //   {
  //     division: "₹ 12,34,567",
  //     ysa: "₹ 12,34,567",
  //     overallChg: "₹ 12,34,567",
  //     sales: "₹ 12,34,567",
  //     overallChgPercent: "₹ 12,34,567",
  //   }
  // ]

  const getFySkuWise = async (type) => {
    const [error, result] = await asyncWrap(axios.get(`/fy-data/sku/${type}`))

    if(!result) {
      console.log("Error in getting current fu sku wise data")
      console.log(error)
      return;
    }

    setFySkuWiseData(result?.data?.data)

    // setting the total amount

    const totalAmount = result?.data?.data?.reduce((acc, cur) => acc + cur?.TotalValue, 0)
    setTotalAmount(totalAmount ?? 0)

    // setting the chart data
    const label = result?.data?.data?.map(item => item?.SkuTypeName)
    const series = result?.data?.data?.map(item => item?.TotalValue)

    setChartData(prev => ({
      ...prev,
      series: series,
      options: {
        ...prev.options,
        labels: label
      }
    }))
  }

  useEffect(() => {
    getFySkuWise(currentFyType)
  }, [currentFyType])

  // getting the home page banner
  useEffect(() => {
    getCmsValues();
  }, [])

  const getCmsValues = async () => {
    const [error, result] = await asyncWrap(axios.get("/cmsValues"));
    if (!result) {
      console.log(error);
      return;
    }
    setHomePageBanner(result.data.data?.HomePageBanner);
  };


  return (
    <div>
      <p style={{ fontSize: "17px", margin: "1em" }}>Wiring Devices</p>
      <div className="d-flex justify-content-end mx-3">
        <Select 
        defaultValue={currentFyType}
        onChange={(value) => setCurrentFyType(value)}
        style={{ width: 105 }}
        options={[
          {
            value: 'current',
            label: 'current FY',
          },
          {
            value: 'previous',
            label: 'previous FY',
          }
        ]}
        />
      </div>
      <SalesChart chartData={chartData} total={totalAmount} />
      <SalesTable column={column} data={data} isShowColor={true} />
      {/* <div className="mt-2">
        <Header text="Yearly Sales Agreement" />
      </div> */}
    
        {/* <YearlySalesTable column={yearlySalesHeader} data={yearlySalesData} /> */}

      <div className="mt-2">
        <Header text={"Brand Loyalty Club"} />
      </div>
      <div className="d-flex gap-2 mx-1 mt-2">
        <CategoryBtn text="Star Rewards" />
        <CategoryBtn text="Discover the world" />
      </div>

      

      {/* slider start */}
      <div>
        {homePageBanner && homePageBanner.length > 0 && (
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper" style={{margin: "1em", height:"180px", width: "auto"}}>
          {homePageBanner.map((item, i) => (
          <SwiperSlide key={i} >
              <img 
              style={{display: "block",width: "100%", height: "100%", objectFit: "contain"}} 
              onClick={() => {
                      const newWindow = window.open(
                        item?.WebLink || item?.PDFPath
                      );
                      if (newWindow) newWindow.opener = null;
                    }} 
                    src={item.BannerImage}
                    alt={item.BannerImage} />
          </SwiperSlide>
          ))}
        </Swiper>
        )}
      </div>
    </div>
  );
}

export default CategoryWiseSales;
