import React, { useEffect, useState } from 'react'
import Header from '../SalesInfo/Header'
import { asyncWrap } from '../../utils/utils'
import axios from 'axios'
import SchemeTable from './SchemeTable'
import SpecialTargetModal from './SpecialTargetModal'
import moment from 'moment'
import SchemeDetailModal from './SchemeDetailModal'

const specialTargetColumn = [
  {
    id: 1,
    name: "Target Name",
    dataIndex: "TargetName",
    key: "SpecialTargetID"
  },
  {
    id: 2,
    name: "Target Type",
    dataIndex: "TargetType",
    key: "SpecialTargetID"
  },
  {
    id: 3,
    name: "Total Qty",
    dataIndex: "TotalQuantity",
    key: "SpecialTargetID"
  },
  {
    id: 4,
    name: "Total Value",
    dataIndex: "TotalValue",
    key: "SpecialTargetID"
  }
]

const schemeColumn = [
  {
    id: 1,
    name: "Scheme Name",
    dataIndex: "SchemeName",
    key: "OrderSchemeID"
  },
  {
    id: 2,
    name: "Type",
    dataIndex: "SchemeTypeName",
    key: "OrderSchemeID"
  },
  {
    id: 3,
    name: "Start Date",
    dataIndex: "StartDate",
    key: "OrderSchemeID"
  },
  {
    id: 4,
    name: "End Date",
    dataIndex: "EndDate",
    key: "OrderSchemeID"
  },
]

function SchemeInfo() {
  const [specialTarget, setSpecialTarget] = useState([])
  const [specialTargetLoading, setSpecialTargetLoading] = useState(true)
  const [invoiceScheme, setInvoiceScheme] = useState([])
  const [invoiceLoading, setInvoiceLoading] = useState(true)
  const [orderScheme, setOrderScheme] = useState([])
  const [orderLoading, setOrderLoading] = useState(true)
  const [growthScheme, setGrowthScheme] = useState([])
  const [growthLoading, setGrowthLoading] = useState(true)
  const [specialTargetModalData, setSpecialTargetModalData] = useState(null)
  const [userAchievementData, setUserAchievementData] = useState(null)
  const [showSpecialTargetModal, setShowSpecialTargetModal] = useState(false)
  const [showInvoiceModal, setShowInvoiceModal] = useState(false)
  const [showOrderModal, setShowOrderModal] = useState(false)
  const [showGrowthModal, setShowGrowthModal] = useState(false)
  const [schemeDetail, setSchemeDetail] = useState([])
  const [clickedScheme, setClickedScheme] = useState(null)

  // show the special target modal 
  const handleSpecialTargetModal = async (data) => {

    setSpecialTargetModalData(data)

    // getting the user achievement
    const [error, result] = await asyncWrap(axios.post("special-target/detail/invoice-total", {
      StartDate: moment(data?.StartDate).format("YYYY-MM-DD"),
      EndDate: moment(data?.EndDate).format("YYYY-MM-DD"),
      SkuType: data?.SkuType
    }))

    if(!result) {
      console.log("Error in getting user achievement")
      console.log(error)
      return;
    }

    setUserAchievementData(result?.data?.data[0])
    setShowSpecialTargetModal(true)
  }

  // getting special target
  const getSpecialTarget = async () => {
    const [error, result] = await asyncWrap(axios.get("special-target"))

    if(!result) {
      console.log("Error in getting special target")
      console.log(error)
      setSpecialTargetLoading(false)
      return;
    }

    setSpecialTarget(result?.data?.data)
    setSpecialTargetLoading(false)
  }

  // getting schemes based on types
  const getScheme = async (type, setData, setLoading) => {
    const [error, result] = await asyncWrap(axios.get(`schemeList/user-scheme?type=${type}`))

    if(!result) {
      console.log("Error in getting special target")
      console.log(error)
      setLoading(false)
      return;
    }

    setData(result?.data?.data)
    setLoading(false)
  }

  // getting schemes detail
  const getDetail = async (type, id, setData) => {
    const [error, result] = await asyncWrap(axios.get(`schemeList/${type}/detail?id=${id}`))
    
    if(error) {
      console.log(`Error in ${type} scheme detail ${error}`)
      return
    }

    setData(result?.data?.data)

    // showing the modal
    if(type === "Invoice") setShowInvoiceModal(true)
    if(type === "Order") setShowOrderModal(true)
    if(type === "Growth") setShowGrowthModal(true)
  }

  const handleSchemeModal = (data) => {
    setClickedScheme(data)
    // get the type of scheme
    const type = data.InvoiceSchemeID ? "Invoice" : data.GrowthSchemeID ? "Growth" : data.OrderSchemeID ? "Order" : null;

    const id = data.InvoiceSchemeID || data.GrowthSchemeID || data.OrderSchemeID

    // call the getDetail
      getDetail(type, id, setSchemeDetail)

  }

  // in the invoice scheme data - we are getting InvoiceSchemeTypeID - add SchemeTypeName using InvoiceSchemeTypeID - id - 1 ==> Based on Sku wise Quantity, id - 2 ==> Based on Sku wise Value, id - 3 ==> Based on Total Value

  let updatedIvoiceData = invoiceScheme.map(item => ({
    ...item, 
    SchemeTypeName: item.InvoiceSchemeTypeID === "1" ? "Based on Sku wise Quantity" :
    item.InvoiceSchemeTypeID === "2" ? "Based on Sku wise Value" :
    item.InvoiceSchemeTypeID === "3" ? "Based on Total Value" : ""
  }))


  useEffect(() => {
    getScheme("Invoice", setInvoiceScheme, setInvoiceLoading)
    getScheme("Order", setOrderScheme, setOrderLoading)
    getScheme("Growth", setGrowthScheme, setGrowthLoading)
    getSpecialTarget()
  }, [])

  return (
    <>
      <Header text="Special Target" />
      <div className='mb-2'>
        <SchemeTable 
        column={specialTargetColumn} 
        data={specialTarget}
        loading={specialTargetLoading}
        onClick={handleSpecialTargetModal}
        pageSize={4}
        setLoading={setSpecialTargetLoading}
        />
      </div>
      <Header text="Invoice Scheme" />
      <div className='mb-2' >
        <SchemeTable 
        column={schemeColumn}
        data={updatedIvoiceData}
        loading={invoiceLoading}
        onClick={handleSchemeModal}
        pageSize={4}
        setLoading={setInvoiceLoading}
        />
      </div>
      <Header text="Order Scheme" />
      <div className='mb-2' >
        <SchemeTable 
        column={schemeColumn}
        data={orderScheme}
        loading={orderLoading}
        onClick={handleSchemeModal}
        pageSize={4}
        setLoading={setOrderLoading}
        />
      </div>
      <Header text="Growth Scheme" />
      <div className='mb-2' >
        <SchemeTable 
        column={schemeColumn}
        data={growthScheme}
        loading={growthLoading}
        onClick={handleSchemeModal}
        pageSize={4}
        setLoading={setGrowthLoading}
        />
      </div>

      {/* modals */}
      <SpecialTargetModal 
      show={showSpecialTargetModal}
      onHide={() => setShowSpecialTargetModal(false)}
      SkuType={specialTargetModalData?.SkuType}
      showAchievement={true}
      specialTargetModalData={specialTargetModalData}
      userAchievementData={userAchievementData}
      />
      {/* invoice detail modal */}
      <SchemeDetailModal 
      show={showInvoiceModal}
      onHide={() => setShowInvoiceModal(false)}
      clickedScheme={clickedScheme}
      schemeDetail={schemeDetail}
      />
      {/* order detail modal */}
      <SchemeDetailModal 
      show={showOrderModal}
      onHide={() => setShowOrderModal(false)}
      clickedScheme={clickedScheme}
      schemeDetail={schemeDetail}
      />
      {/* growth detail modal */}
      <SchemeDetailModal 
      show={showGrowthModal}
      onHide={() => setShowGrowthModal(false)}
      clickedScheme={clickedScheme}
      schemeDetail={schemeDetail}
      />
    </>
  )
}

export default SchemeInfo