import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ModalItem from './ModalItem';
import moment from 'moment';


function SpecialTargetModal({show, onHide ,showAchievement, specialTargetModalData, userAchievementData}) {
  return (
    <div className='mx-auto'>
    <Modal isOpen={show} toggle={onHide} className="mx-5ß d-flex"
              style={{marginTop: "12vh"}} >
      <ModalHeader className='text-center' toggle={onHide}>Target Detail</ModalHeader>
      <ModalBody>
        <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Target Name"} text={specialTargetModalData?.TargetName} />
            </div>
            <div>
            <ModalItem heading={"Target Type"} text={specialTargetModalData?.TargetType} />
            </div>
        </div>
        <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Start Date"} text={moment(specialTargetModalData?.StartDate).format("DD-MM-YYYY")} />
            </div>
            <div>
            <ModalItem heading={"End Date"} text={moment(specialTargetModalData?.EndDate).format("DD-MM-YYYY")} />
            </div>
        </div>
        <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Total Value"} text={specialTargetModalData?.TotalValue} />
            </div>
            <div>
            <ModalItem heading={"Total Quantity"} text={specialTargetModalData?.TotalQuantity} />
            </div>
        </div>
        <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Sku Type"} text={specialTargetModalData?.SkuType} />
            </div>
        </div>
        {showAchievement && (
            <div>
                <hr />
                <h5>Your Achievement</h5>
            </div>
        )}
        <div className='m-3 d-flex justify-content-between'>
            <div>
            <ModalItem heading={"Total Value"} text={userAchievementData?.TotalValue ?? 0} />
            </div>
            <div>
            <ModalItem heading={"Total Quantity"} text={userAchievementData?.TotalQty ?? 0} />
            </div>
        </div>
      </ModalBody>
    </Modal>
    </div>
  )
}

export default SpecialTargetModal