import React, { useEffect, useState } from 'react'
import Header from '../SalesInfo/Header'
import PaymentHeader from './PaymentHeader'
import SalesTable from '../CategoryWiseSales/SalesTable'
import PaymentTable from './PaymentTable'
import { AiOutlineFilePdf } from "react-icons/ai";
import PaymentBtn from './PaymentBtn'
import { asyncWrap } from '../../utils/utils'
import axios from 'axios'
import SchemeTable from '../SchemeInfo/SchemeTable';
import { useAuth } from "../../context/auth-context"

const agingReportColumn = [
  {
    id: 1,
    name: "Period (Days)",
  },
  {
    id: 2,
    name: "Amount",
  },
];

const agingReportData = [
  {
    period: "0-30",
    amount: "₹ 18,077"
  },
  {
    period: "31-60",
    amount: "₹ 10,16,660"
  },
  {
    period: "61-90",
    amount: "₹ 11,74,434"
  },
  {
    period: "91-120",
    amount: "₹ 3,19,434"
  }
];

const lastPaymentColumns = [
  {
    id: 1,
    name: "Date",
  },
  {
    id: 2,
    name: "Amount",
  },
  {
    id: 3,
    name: "Mode",
  },
  {
      id: 4,
      name: "Status",
    },
];

const lastPaymentDatas = [
  {
    date: "05-03-2020",
    amount: "₹ 26,551",
    mode: "55.36%",
    status: "Approved"
  },
  {
    date: "22-02-2020",
    amount: "₹ 26,551",
    mode: "55.36%",
    status: "Approved"
  },
  {
    date: "20-02-2020",
    amount: "₹ 26,551",
    mode: "55.36%",
    status: "Approved"
  },
  {
    date: "18-02-2020",
    amount: "₹ 26,551",
    mode: "55.36%",
    status: "Approved"
  },
  {
    date: "06-02-2020",
    amount: "₹ 26,551",
    mode: "55.36%",
    status: "Approved"
  },
  {
    date: "05-02-2020",
    amount: "₹ 26,551",
    mode: "55.36%",
    status: "Approved"
  }
];

const specialTargetColumn = [
  {
    id: 1,
    name: "Mobile Number",
    dataIndex: "MobileNumber",
    key: "SrNo"
  },
  {
    id: 2,
    name: "Name",
    dataIndex: "FullName",
    key: "SrNo"
  },
  {
    id: 3,
    name: "Transaction Id",
    dataIndex: "TransId",
    key: "SrNo"
  },
  {
    id: 4,
    name: "Transaction Date",
    dataIndex: "TranscationDate",
    key: "SrNo"
  },
  {
    id: 5,
    name: "Withdraw Type",
    dataIndex: "WithdrawType",
    key: "SrNo"
  },
  {
    id: 6,
    name: "Amount",
    dataIndex: "Amount",
    key: "SrNo"
  },
  {
    id: 7,
    name: "Closing Balance",
    dataIndex: "ClosingBalance",
    key: "SrNo"
  },
  {
    id: 8,
    name: "Descriptions",
    dataIndex: "Descriptions",
    key: "SrNo"
  },
]

function PaymentInfo() {
  const [invoiceLedger, setInvoiceLedger] = useState([])
  const [closingBalance, setClosingBalance] = useState(0)
  const [invoiceLoading, setInvoiceLoading] = useState(true)

  const { user } = useAuth()


  // getting the user's invoice ledger
  const getInvoiceLedger = async () => {
    const [error, result] = await asyncWrap(axios.get(`/user-invoice/ledger?mobile=${user?.profile?.mobileNumber}`))

    if(error) {
      console.log("user invoice ledger error", error)
      setInvoiceLoading(false)
      return
    }

    if(result.data.data) {
      setInvoiceLedger(result.data.data)
      setClosingBalance(result.data.data[result.data.data.length - 1]?.ClosingBalance)
    }
    setInvoiceLoading(false)
  }

  useEffect(() => {
    getInvoiceLedger()
  }, [])

  console.log(invoiceLedger)
  
  return (
    <div>
      <Header text="Outstanding payment"/>
      <PaymentHeader balance={closingBalance} />
      <SchemeTable 
        column={specialTargetColumn} 
        data={invoiceLedger}
        loading={invoiceLoading}
        onClick={null}
        pageSize={4}
        setLoading={setInvoiceLoading}
        />
      <PaymentTable/>
      <div className='mt-2'></div>
      <Header text="Aging Report"/>
      <SalesTable column={agingReportColumn} data={agingReportData} />
      <PaymentBtn text={<>Download PDF <span><AiOutlineFilePdf size={18} className='mb-1' color='white'/></span> </>} style={{borderRadius:"0px", border: 'none'}}/>
      <div className='mt-2'></div>
      <Header text="Last Payments"/>
      
      <SalesTable column={lastPaymentColumns} data={lastPaymentDatas}/>
      <PaymentBtn text="All Payments" style={{borderRadius:"0px", border: 'none'}} />
    </div>
  )
}

export default PaymentInfo